<!--
 * @Descripttion: 
 * @version: 1.0
 * @Author: horiz0n_Z
 * @Date: 2020-11-17 22:05:46
 * @LastEditors: horiz0n_Z
 * @LastEditTime: 2021-08-23 22:23:48
-->
<template>
  <div class="page-box">
    <div class="card">
      <memo-box :title="'工单id:' + info.number">
        <div slot="body" class="deital">
          <div class="row" data-title="品牌区域">
            <span>{{ info.brandAreaName }}</span>
          </div>
          <div class="row" data-title="店铺名称">
            <span>{{ info.shopName }}</span>
          </div>
          <div class="row" data-title="门店地址">
            <span>{{ info.address }}</span>
          </div>
          <div class="row" data-title="联 系 人">
            <span>{{ info.contactPerson }}</span>
          </div>
          <div class="row" data-title="联系电话">
            <span>{{ info.contactMobile }}</span>
          </div>
          <div class="row" data-title="报修项目">
            <span>{{ info.repairTypeName }}</span>
          </div>
          <div class="row" data-title="报修类型">
            <span class="tag" v-if="!info.repairLevel">非紧急</span>
            <span class="tag tag--wrong" v-else>紧急</span>
          </div>
          <div class="row" data-title="上门维修">
            <span>{{ info.serviceTime }}</span>
          </div>
          <div class="row" data-title="勘察时间">
            <span>{{ info.surveyTime }}</span>
          </div>
          <div class="row" data-title="报修描述">
            <span class="intro">{{ info.description }}</span>
          </div>
          <div class="row" data-title="拍照图片">
            <ul class="imgs">
              <li
                class="img-box"
                v-for="(item, index) in info.photo"
                :key="index"
                @click="$previewImgs(info.photo)"
              >
                <img :src="item" alt="" />
              </li>
            </ul>
          </div>

          <div class="row" data-title="目前状态">
            <span>{{ checkStatus(info, orderStatus).name }}</span>
          </div>

          <div v-if="servicePhoto.length || serviceRemark">
            <div class="row__line"></div>

            <div class="row" data-title="备注" v-if="serviceRemark">
              <span>{{ serviceRemark }}</span>
            </div>

            <div class="row" data-title="维修照片" v-if="servicePhoto.length">
              <ul class="imgs">
                <li
                  class="img-box"
                  v-for="(item, index) in servicePhoto"
                  :key="index"
                  @click="$previewImgs(servicePhoto)"
                >
                  <img :src="item" alt="" />
                </li>
              </ul>
            </div>
            <div class="row" data-title="维修报告" v-if="serviceReport.length">
              <ul class="imgs">
                <li
                  class="img-box"
                  v-for="(item, index) in serviceReport"
                  :key="index"
                  @click="$previewImgs(serviceReport)"
                >
                  <img :src="item" alt="" />
                </li>
              </ul>
            </div>
          </div>

          <div class="btns flex-x center between">
            <div
              class="btn"
              v-for="(item, index) in checkStatus(info, orderStatus).btns || []"
              :key="index"
              :class="item.type === 'primary' ? 'btn--confirm' : ''"
              @click="clickBtn(item)"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
      </memo-box>
    </div>

    <msg-box v-model="showNone" @onclick="$router.back()" />

    <turn-down
      v-model="showTurnDown"
      @onsubmit="submitTurnDown"
      ref="turnDown"
    ></turn-down>
  </div>
</template>

<script>
import { Toast } from "vant";
import msgBox from "../../../components/msgBox/msgBox";
import turnDown from "../../../components/turnDown/turnDown.vue";
import MemoBox from "@/components/MemoBox/MemoBox";
import {
  orderDetail,
  order,
  fillTime,
  settlementAmount,
} from "../../../utils/api/workOrder";

import { orderDetail as serviceOrderDetail } from "../../../utils/api/acceptance";
export default {
  components: {
    "memo-box": MemoBox,
    "msg-box": msgBox,
    "turn-down": turnDown,
  },

  data() {
    return {
      info: { review: {} }, // 订单详情

      serviceRemark: "", // 维修备注
      servicePhoto: [], // 维修图片
      serviceReport: [], // 维修报告

      showNone: false, // 显示没有列表

      showTime: false, // 显示勘察时间

      showVisitTime: false, // 显示上门时间

      showTurnDown: false, // 显示驳回弹窗

      prepareInfo: 0, // 准备操作的内容

      amount: 0, // 结算金额

      orderStatus: [
        {
          name: "待审核",
          condition: { status: 0, "review.status": 0 },
        },
        {
          name: "待审核",
          condition: { status: 0, "review.status": 2 },
          btns: [{ name: "重新报修", type: "primary", method: "repairAgain" }],
        },
        {
          name: "待派单",
          condition: { status: 1 },
        },
        {
          name: "待报价",
          condition: { status: 2, label: 0 },
        },
        {
          name: "待报价",
          condition: { status: 2, label: true, isAuth: 0 },
          btns: [{ name: "查看报价", method: "viewOffer" }],
        },
        {
          name: "待报价",
          condition: { status: 2, label: true, isAuth: 1 },
          btns: [
            { name: "查看报价", method: "viewOffer" },
            { name: "确认报价", type: "primary", method: "confirmOffer" },
          ],
        },
        {
          name: "待维修",
          condition: { status: 3 },
        },
        {
          name: "待验收",
          condition: { status: 4, label: 0, isAuth: 1 },
          btns: [
            { name: "驳回", method: "turnDown" },
            { name: "立即验收", method: "acceptance" },
          ],
        },
        {
          name: "待支付",
          condition: { status: 5 },
          btns: [{ name: "支付", type: "primary", method: "pay" }],
        },
        {
          name: "已完成",
          condition: { status: 6 },
        },
      ],
    };
  },

  created() {
    this.getDetail();
  },

  methods: {

    // 获取订单详情
    getDetail() {
      orderDetail({ id: this.$route.query.id })
        .then((res) => {
          let info = res.data.data;
          this.info = info;
          // 获取维修单
          if (
            (info.otherStatus === 2 && info.label.id) ||
            info.otherStatus === 3
          ) {
            this.getServiceOrder(info.label.id);
          }
        })
        .catch((res) => {
          this.$router.back();
          // Toast(res.data.msg);
        });
    },

    // 获取维修单
    getServiceOrder(id) {
      serviceOrderDetail({
        id,
      })
        .then((res) => {
          this.servicePhoto = res.data.data.service.photo;
          this.serviceReport = res.data.data.service.report;
        })
        .catch((res) => {
          Toast(res.data.msg);
        });
    },

    // 检查转台
    checkStatus(info, status) {
      for (let i = 0; i < status.length; i++) {
        let condition = status[i].condition;

        if (this.checkCondition(info, condition)) {
          return status[i];
        }
      }
      return "";
    },

    // 检查条件内容
    checkCondition(obj, condition) {
      let checkPassed = true; // 检查通过
      for (let j in condition) {
        let position = j.split(".");
        let value = obj;
        while (position.length > 0) {
          value = value[position[0]];
          position.splice(0, 1);
        }
        if (typeof condition[j] === "boolean") {
          if (condition[j] !== !!value) {
            checkPassed = false;
            break;
          }
        } else {
          if (condition[j] !== value) {
            checkPassed = false;
            break;
          }
        }
      }
      return checkPassed;
    },

    // 点击按钮
    clickBtn(btn) {
      if (!btn.method) return false;
      this[btn.method](this.info.id, this.info, btn);
    },

    // 支付
    pay(id, info) {
      this.$router.push({
        name: "UserPay",
        query: {
          id,
        },
      });
    },

    // 重新报修
    repairAgain() {
      this.$router.push({
        name: "UserRepair",
      });
    },

    // 查看报价
    viewOffer(id, info) {
      this.$router.push({
        name: "UserOffer",
        query: {
          id: info.label,
        },
      });
    },

    // 确认报价
    confirmOffer(id, info) {
      confirm({
        id: info.label,
        review: 1,
      })
        .then((res) => {
          Toast.success("确认成功");
          this.getDetail();
        })
        .catch((res) => {
          Toast(res.data.msg);
        });
    },

    // 驳回验收
    turnDown(id, info, btn) {
      this.prepareInfo = info;
      this.showTurnDown = true;
    },

    // 发送驳回
    submitTurnDown(e) {
      confirmAcceptance({
        id: this.prepareInfo.label,
        review: 2,
        reason: e.detail.text,
      })
        .then((res) => {
          Toast.success("驳回成功");
          this.showTurnDown = false;
          this.$refs.turnDown.clear();
          this.getDetail();
        })
        .catch((res) => {
          Toast(res.data.msg);
        });
    },

    // 立即验收
    acceptance(id, info) {
      confirmAcceptance({
        id: info.label,
        review: 1,
      })
        .then((res) => {
          Toast.success("验收成功");
          this.getDetail();
        })
        .catch((res) => {
          Toast(res.data.msg);
        });
    },
  },
};
</script>

<style src="@/assets/css/review/newTicketDetail.css" scoped>
</style>